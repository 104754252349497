// eslint-disable-next-line import/no-cycle
import { db } from '@/main'
export default {
  namespaced: true,

  state: {
    site: {},
    mSites: [],   // site record referred by marketers
    isSiteLoaded: false,
    isMSitesLoaded: false,
    isNewSiteCreated: false, // record if new site is created
  },

  actions: {
    async getSite({ commit }, payload) {
      let snapshot = await db.collection('sites')
      .where('cusId', '==', payload)
      .get()
      let sites = [];
      snapshot.forEach(doc => {
        let appData = doc.data()
        appData.id = doc.id
        sites.push(appData)
      })
      commit('SET_SITE', sites[0])
      commit('SET_ISSITELOADED', true)
    },

    async fetchMSites({ commit }, promoCodes) {
      const promises = promoCodes.map(promoCode => {
        return db.collection('sites') // Use Firestore collection
          .where('promoCode', '==', promoCode) // Query by promoCode
          .get()
          .then(snapshot => {
            const sites = [];
            snapshot.forEach(doc => {
              let appData = doc.data();
              appData.id = doc.id; // Add document ID
              sites.push(appData);
            });
            return sites;
          });
      });
    
      const sitesArray = await Promise.all(promises);
      commit('SET_MSITES', sitesArray.flat());
      
      // Log the fetched sites for debugging
      // console.log('Sites fetched:', sitesArray.flat());
      
      return sitesArray.flat();
    },
    
    async getMarketerSites({ commit }, payload) {
      // console.log('getMarketerSites payload: ' + payload)
      let snapshot = await db.collection('sites')
      .where('promoCode', '==', payload)
      .get()
      let msites = [];
      snapshot.forEach(doc => {
        let appData = doc.data()
        appData.id = doc.id
        msites.push(appData)
      })
      // console.log('getMarketerSites msites.length: ' + msites.length)
      commit('SET_MSITES', msites)
      commit('SET_ISMSITESLOADED', true)
    },

    async addSite({ commit }, payload) {
      await db
        .collection('sites')
        .add({
          cusId: payload.cusId,
          regUser: payload.regUser,
          regUserName: payload.regUserName,
          regUserEmail: payload.regUserEmail,
          since: payload.since,
          till: payload.till,
          till_purchasedStorage: payload.till_purchasedStorage,
          plan: payload.plan,
          numOfUsersLimit: payload.numOfUsersLimit,
          numOfUsersUsed: payload.numOfUsersUsed,
          storageUsed: payload.storageUsed,
          planStorage: payload.planStorage,
          purchasedStorage: payload.purchasedStorage,
          sub_numTier: payload.sub_numTier,
          sub_numStorage: payload.sub_numStorage,
          sub_isResolved: payload.sub_isResolved,
          createdAt: payload.createdAt,
          updatedAt: payload.updatedAt,
          // zPartnerCode: payload.zPartnerCode,
          promoCode: payload.promoCode,
          promoCodeChecked: payload.promoCodeChecked
        })
        .then(function (docRef) {
          payload.id = docRef.id
        })
      commit('SET_SITE', payload)
      // commit('SET_ISSITELOADED', true)
      // commit('SET_NEWSITECREATED')
    },
    
    async updateSite({ commit }, payload) {
      await db
        .collection('sites')
        .doc(payload.id)
        .update({
          cusId: payload.cusId,
          regUser: payload.regUser,
          regUserName: payload.regUserName,
          regUserEmail: payload.regUserEmail,
          since: payload.since,
          till: payload.till,
          till_purchasedStorage: payload.till_purchasedStorage,
          plan: payload.plan,
          numOfUsersLimit: payload.numOfUsersLimit,
          numOfUsersUsed: payload.numOfUsersUsed,
          storageUsed: payload.storageUsed,
          planStorage: payload.planStorage,
          purchasedStorage: payload.purchasedStorage,
          sub_numTier: payload.sub_numTier,
          sub_numStorage: payload.sub_numStorage,
          sub_isResolved: payload.sub_isResolved,
          createdAt: payload.createdAt,
          updatedAt: payload.updatedAt,
          // zPartnerCode: payload.zPartnerCode,
          promoCode: payload.promoCode,
          promoCodeChecked: payload.promoCodeChecked
        })
      commit('SET_SITE', payload)
    },
  },

  getters: {},

  mutations: {
    SET_SITE(state, payload) {
      state.site = payload
    },
    SET_MSITES(state, payload) {
      const uniqueSites = payload.filter(newSite => 
        !state.mSites.some(existingSite => existingSite.id === newSite.id)
      );
      state.mSites = state.mSites.concat(uniqueSites);
    },
    // SET_MSITES(state, payload) {
    //   state.mSites = state.mSites.concat(payload)
    // },
    SET_ISSITELOADED(state, payload) {
      state.isSiteLoaded = payload
    },
    SET_ISMSITESLOADED(state, payload) {
      state.isMSitesLoaded = payload
    },
    SET_NEWSITECREATED(state, payload) {
      state.isNewSiteCreated = payload
    },
  },

}
