import { db } from '@/main'

export default {
  namespaced: true,
  state: {
    dailyHistoryItems: [],
    mail: {},
  },
  actions: { 
    // Get History Items when:
    // 1. 'usersNotified' array contains the user's id
    // 2. 'at' is today
    async getDailyHistoryItems({ commit }, payload) {
    
      // Generate today's start and tomorrow's start timestamps
      const getTodayRange = () => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);
    
        const formatDate = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          return `${year}-${month}-${day} 00:00`;
        };
    
        return {
          todayStart: formatDate(today),
          tomorrowStart: formatDate(tomorrow)
        };
      };
    
      const { todayStart, tomorrowStart } = getTodayRange();
    
      try {
        const snapshot = await db.collection('hist_app')
          .where('at', '>=', todayStart) // Server-side date filter
          .where('at', '<', tomorrowStart)
          .get();
    
        // Client-side array check
        const results = [];
        snapshot.forEach(doc => {
          const data = doc.data();
          if (data.usersNotified && data.usersNotified.length > 0) {
            results.push({
              id: doc.id,
              ...data
            });
          }
        });
    
        commit('SET_DAILY_HISTORY', results);
        return results;
      } catch (error) {
        console.error('Error fetching daily history:', error);
        throw error;
      }
    },
    async sendEmail({ commit }, payload) {
      await db
        .collection('mail')
        .add({
          message: payload.message,
          to: payload.to
        })
        .then(function (docRef) {
          payload.id = docRef.id
        })
      commit('SET_SENDEMAIL', payload)
    },
    // Check if notification was already sent today
    async hasNotificationBeenSent({ commit }, payload) { // Fix 1: Proper payload handling
      try {
        // Fix 2: Parameter validation
        if (!payload?.userId || !payload?.taskId || !payload?.date) {
          console.error('Invalid payload:', payload);
          return { exists: true }; // Fail-safe return
        }
  
        const { userId, taskId, date } = payload;
        
        // Fix 3: Add type checking
        if (typeof userId !== 'string' || typeof taskId !== 'string' || typeof date !== 'string') {
          console.error('Invalid parameter types:', { userId, taskId, date });
          return { exists: true };
        }
  
        const snapshot = await db.collection('mailNotifications')
          .where('userId', '==', userId)
          .where('taskId', '==', taskId)
          .where('sentDate', '==', date)
          .limit(1)
          .get();
  
        console.log('Firestore query results:', snapshot.docs.length);
        return { exists: !snapshot.empty };
  
      } catch (error) {
        console.error('Firestore query failed:', error);
        return { exists: true }; // Prevent email spamming on errors
      }
    },
    // async hasNotificationBeenSent(userId, taskId, date) {
    //   const snapshot = await db.collection('mailNotifications')
    //     .where('userId', '==', userId)
    //     .where('taskId', '==', taskId)
    //     .where('sentDate', '==', date)
    //     .limit(1)
    //     .get();

    //   return !snapshot.empty;
    // },
    // Record successful email dispatch
    async recordNotificationSent({ commit }, payload) {
      try {
        // Validate required fields with correct names
        if (!payload?.userId || !payload?.taskId || !payload?.sentDate) {
          throw new Error(`Missing required fields. Received: ${JSON.stringify(payload)}`);
        }
    
        const { userId, taskId, sentDate, userEmail, taskName } = payload;
    
        // Add type checking
        if (typeof userId !== 'string' || typeof taskId !== 'string' || typeof sentDate !== 'string') {
          throw new Error(`Invalid parameter types: ${typeof userId}/${typeof taskId}/${typeof sentDate}`);
        }
    
        // Add document with full context
        const docRef = await db.collection('mailNotifications').add({
          userId,
          taskId,
          sentDate,
          userEmail: userEmail || 'unknown',
          taskName: taskName || 'unnamed-task',
        });
    
        console.log(`Notification recorded: ${docRef.id}`);
        return true;
    
      } catch (error) {
        console.error('Notification recording failed:', error.message);
        throw error; // Preserve error chain
      }
    },
    // async recordNotificationSent(userId, taskId, date) {
    //   await db.collection(mailNotifications).add({
    //     userId: userId,
    //     taskId: taskId,
    //     sentDate: date,
    //     timestamp: firebase.firestore.FieldValue.serverTimestamp()
    //   });
    // },
  },
  mutations: {
    SET_DAILY_HISTORY(state, payload) {
      state.dailyHistoryItems = payload
      state.dailyHistoryItems.update
    },
    SET_SENDEMAIL(state, payload) {
      state.mail = payload
    },
  },
}