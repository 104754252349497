import { db } from '@/main'
import { format, subMinutes } from 'date-fns'

const state = () => ({
  taskDrag: {},  // task being dragged
  taskSize: 0,
  sortBy: 'due-date',
  filterBy: 'assignee',
  filterValue: '',
  dueFilterBy: 'Today',
  tasks: [],
  // selectedTasks: [],
  task: {},
  newTaskParent: {}, //past parent values to new task (i.e. "new subtask")
  taskBeforeUpdate: {},
  rootTask: {},
  blankTaskParent: {},  // i.e. Blank Test with Parent ID and Type
  taskView: 'detailed',
  taskTags: [],
  notAddTags: false,
  addTaskTag: false,
  taskPlanView: '',
  searchTaskString: '',
  taskSidebar: false,
  // dataFrom: '2022-09-01',
  // dataTo: '2022-10-01',
  resetCardTaskPlan: false,
  taskIdToEditInTreeView: '',  // allow edit view getting back to tree view
  // selectedChannelIdRParam: '', // Router parameter stored value

  numOfOverdueTasks: 0,   // todo leftsidebar params
  numOfOutstandingTasks: 0,
  numOfUpcomingTasks: 0,
  numOfTodayTasks: 0,
  numOfAnytimeTasks: 0,
  isDueFiltersUpdated: false,
  tasksGroupBy: 'Date',   // grouped by 'Date', 'Goal', 'Status' or ''

  selectedTaskIdRParam: '', // routed from Navbar
  routeFromRParam: '',
  booShowCompletedTasks: false, // Control showing completed tasks across the task tree

  renderMaxTaskLevel: 99,

  rescheduleTasksView: false,
  rescheduleMode: '', // 'Manual' / 'Auto / 'Reset'
  reschedulingLevel: -1,
  saveRescheduleTasks: false,
  datesManuallySet: false,
  refreshDays: [],
  oriTaskList: [],  // for backup of taskList before rescheduling tasks
  isDraggable: false,

  // Tree cf values:
  cfTreeValues: []  // id/0/1/.../9
})

const getters = {
  fetchLatestTask: (state) => (id) => {
    return state.tasks[state.tasks.length-1]
  },
  fetchTasksByParentId: (state) => (id) => {
    return state.tasks.filter(task => task.zParentId === id)
  },
  fetchTasksByModule: (state) => (module) => {
    return state.tasks.filter(task => (task.module==module && task.deleted==false))
  }
};

const actions = {
  setIsDraggable({ commit }, payload) {
    commit('SET_ISDRAGGABLE', payload)
  },
  setRenderMaxTaskLevel({ commit }, payload) {
    commit('SET_RENDERMAXTASKLEVEL', payload)
  },
  setRescheduleTasksView({ commit }, payload) {
    commit('SET_RESCHEDULETASKSVIEW', payload)
  },
  setRescheduleMode({ commit }, payload) {
    commit('SET_RESCHEDULEMODE', payload)
  },
  setReschedulingLevel({ commit }, payload) {
    commit('SET_RESCHEDULINGLEVEL', payload)
  },
  setSaveRescheduleTasks({ commit }, payload) {
    commit('SET_SAVERESCHEDULETASKS', payload)
  },
  setDatesManuallySet({ commit }, payload) {
    commit('SET_DATESMANUALLYSET', payload)
  },
  addUpdateRefreshDays({ commit }, payload) {
    commit('ADDUPDATE_REFRESHDAYS', payload)
  },
  setRefreshDays({ commit }, payload) {
    commit('SET_REFRESHDAYS', payload)
  },
  addUpdateOriTaskList({ commit }, payload) {
    commit('ADDUPDATE_ORITASKLIST', payload)
  },
  setOriTaskList({ commit }, payload) {
    commit('SET_ORITASKLIST', payload)
  },
  setTaskDrag({ commit }, payload) {
    commit('SET_TASKDRAG', payload)
  },
  setSelectedTaskIdRParam({ commit }, payload) {
    commit('SET_SELECTEDTASKIDPARAM', payload)
  },
  setRouteFromRParam({ commit }, payload) {
    commit('SET_ROUTEFROMRPARAM', payload)
  },
  addCfTreeValues({ commit }, payload) {
    commit('ADD_CFTREEVALUES', payload)
  },
  setCfTreeValues({ commit }, payload) {
    commit('SET_CFTREEVALUES', payload)
  },
  async getTaskSize({ state }, payload) {
    let snapshot = await db.collection('tasks')
      .where('zCusId', '==', payload)
      .get()
      // .then(snap => {
      //   state.taskSize = snap.size // will return the collection size
      // });
    state.taskSize = snapshot.size
  },
  // Get a Task, payload is task id
  async getTask({ commit }, taskId) {
    try {
      const doc = await db.collection('tasks').doc(taskId).get();
      if (doc.exists) {
        const task = {
          id: doc.id,
          ...doc.data()
        };
        commit('SET_TASK', task);
        return task; // Explicit return
      }
      return null;
    } catch (error) {
      console.error('Error fetching task:', error);
      throw error;
    }
  },
  // async getTask({ commit }, payload) {
  //   let snapshot = await db.collection('tasks')
  //     .where('id', '==', payload)
  //     .get()
  //   let tasks = [];
  //   snapshot.forEach(doc => {
  //     let appData = doc.data()
  //     appData.id = doc.id
  //     tasks.push(appData)
  //   })
  //   commit('SET_TASK', tasks[0])
  // },
  async getTasks({ commit, dispatch }, payload) {
    const tasksRef = db.collection('tasks')
    const isCusIdMatch = tasksRef.where('zCusId', '==', payload).limit(500).get()
    const isAssigneeMatch = tasksRef.where('assignee', 'array-contains', payload).limit(1000).get()
    
    const [cusIdQuerySnapshot, assigneeQuerySnapshot] = await Promise.all([
      isCusIdMatch,
      isAssigneeMatch
    ]);

    const cusIdArray = cusIdQuerySnapshot.docs;
    const assigneeArray = assigneeQuerySnapshot.docs;

    const tasksSnapshot = cusIdArray.concat(assigneeArray);

    let tasks = []
    tasksSnapshot.forEach(doc => {
      let appData = doc.data()
      appData.id = doc.id
       tasks.push(appData)
    })
    commit('SET_TASKS', tasks)
    dispatch('orderTagsFromTasks', tasks)
  },
  async getDeltaTasks({ commit, rootState, }, payload) {
    let snapshot = await db.collection('tasks')
      .where('zCusId', '==', payload)
      .where('updatedAt', '>=', rootState.authen.loginTime + '\uf8ff')
      .limit(50)
      .get()
    let deltaTasks = [];
    snapshot.forEach(doc => {
      let appData = doc.data()
      appData.id = doc.id
      // console.log('task to be added: ' + doc.id)
      deltaTasks.push(appData)
    })
    // console.log('no. of delta tasks: ' + deltaTasks.length)
    commit('ADD_DELTATASKS', deltaTasks)

    deltaTasks.forEach(deltaTask => {
      if (deltaTask.module=='task') {
        commit('ADD_TASKTAGS', deltaTask.tags)
      }
    })
      
  },
  async batchDeleteTasks() {
    // First perform the query
    await db.collection('tasks').where('module','==','channel').get()
    .then(function(querySnapshot) {
          // Once we get the results, begin a batch
          var batch = db.batch();

          querySnapshot.forEach(function(doc) {
              // For each doc, add a delete operation to the batch
              batch.delete(doc.ref);
          });

          // Commit the batch
          return batch.commit();
          // console.log('store - delete record done.')
      }).then(function() {
          // Delete completed!
          // ...
      }); 
  },
  // Prepare a new task tags array
  // payload is 'tasks'
  // vs 'ADD_TASKTAGS': change in state.taskTags
  // 'getTasks', 'updateTask', 'deleteTask'
  orderTagsFromTasks({ commit }, payload) {
    let taskTagsArray = []
    let uniqueArray = []

    // 1. Concat tags from all tasks.tags into taskTagsArray
    payload
      .filter(payloadd => payloadd.module=='task')
      .forEach(task => taskTagsArray = taskTagsArray.concat(task.tags))

    // 2. Dudup taskTagsArray
    taskTagsArray.forEach(tag => {
      if ( uniqueArray.indexOf(tag)==-1 && tag.trim()!=='' ) {
        uniqueArray.push(tag)
      }
    })

    commit('SET_TASKTAGS', uniqueArray)
  },
  async addTask({ commit, dispatch }, payload) {
    await db
        .collection('tasks')
        .add({
          module: payload.module,
          taskName: payload.taskName,
          type: payload.type,
          description: payload.description,
          milestone: payload.milestone,
          startOn: payload.startOn,
          dueOn: payload.dueOn,
          reminder: payload.reminder,
          status: payload.status,
          important: payload.important,
          assignee: payload.assignee,
          owner: payload.owner,
          tags: payload.tags,
          zParentId: payload.zParentId,
          zParentType: payload.zParentType,
          zLevel: payload.zLevel,
          zDependencyType: payload.zDependencyType,
          zDependentTask: payload.zDependentTask,
          createdAt: payload.createdAt,
          updatedAt: payload.updatedAt,
          deleted: payload.deleted,
          zCusId: payload.zCusId,
          zCF0: payload.zCF0,
          zCF1: payload.zCF1,
          zCF2: payload.zCF2,
          zCF3: payload.zCF3,
          zCF4: payload.zCF4,
          zCF5: payload.zCF5,
          zCF6: payload.zCF6,
          zCF7: payload.zCF7,
          zCF8: payload.zCF8,
          zCF9: payload.zCF9,
          zPosition: payload.zPosition
        })
        .then(function (docRef) {
          payload.id = docRef.id
        })
    commit('SET_TASK', payload)

    // Solved.. add to "tasks" by tasks.push
    // seems working, test in comment:
    commit('ADD_TOTASKS', payload)

    if (payload.module=='task') {
      commit('ADD_TASKTAGS', payload.tags)
    }
    
    payload.instr = 'Add'
    dispatch('packageHistoryItem', payload)
  },
  async updateTask({ commit, dispatch, state }, payload) {    
    if ( payload.zDependencyType == undefined ) {
      payload.zDependencyType=''
    }
    if ( payload.zDependentTask == undefined ) {
      payload.zDependentTask=''
    }
    await db
      .collection('tasks')
      .doc(payload.id)
      .update({
        module: payload.module,
        taskName: payload.taskName,
        type: payload.type,
        description: payload.description,
        milestone: payload.milestone,
        startOn: payload.startOn,
        dueOn: payload.dueOn,
        reminder: payload.reminder,
        status: payload.status,
        important: payload.important,
        assignee: payload.assignee,
        owner: payload.owner,
        tags: payload.tags,
        zParentId: payload.zParentId,
        zParentType: payload.zParentType,
        zLevel: payload.zLevel,
        zDependencyType: payload.zDependencyType,
        zDependentTask: payload.zDependentTask,
        createdAt: payload.createdAt,
        updatedAt: payload.updatedAt,
        deleted: payload.deleted,
        zCusId: payload.zCusId,
        zCF0: payload.zCF0,
        zCF1: payload.zCF1,
        zCF2: payload.zCF2,
        zCF3: payload.zCF3,
        zCF4: payload.zCF4,
        zCF5: payload.zCF5,
        zCF6: payload.zCF6,
        zCF7: payload.zCF7,
        zCF8: payload.zCF8,
        zCF9: payload.zCF9,
        zPosition: payload.zPosition
      })
    commit('SET_TASK', payload)

    dispatch('orderTagsFromTasks', state.tasks)
    
    if ( !payload.deleted ) {
      payload.instr = 'Update'
    } else {
      payload.instr = 'Remove'
    }
    dispatch('packageHistoryItem', payload)
  },
  async deleteTask({ state, dispatch }, payload) {
    // delete history item first
    payload.instr = 'Delete'
    dispatch('packageHistoryItem', payload)

    let payloadIndex = -1
    await db.collection("tasks").doc(payload.id).delete().then(() => {
        payloadIndex = state.tasks.indexOf(payload)
        if (payloadIndex > -1) {
          state.tasks.splice( payloadIndex, 1 )
          // commit('SET_TASK', state.tasks[state.tasks.length-1])
        }
    }).catch((error) => {
        console.error("Error removing task: ", error);
    });
    
    dispatch('orderTagsFromTasks', state.tasks)
  },
  async packageHistoryItem({ dispatch, rootState }, payload) {

    // 1. prepare historyItem fields (other than 'action')
    let historyItem = {}
    let fuAction = ''
    
    historyItem.module = 'Task'
    historyItem.moduleId = payload.id
    historyItem.moduleParentId = payload.zParentId
    historyItem.moduleParentType = payload.zParentType

    if (payload.reminder !== -1) {
      historyItem.at = format(new Date(
          subMinutes(new Date(payload.startOn), payload.reminder)
        ), "yyyy-MM-dd HH:mm")
    } else {
      historyItem.at = format(new Date(), "yyyy-MM-dd HH:mm")
    }
    historyItem.by = payload.owner
    
    // wrap up notification list, i.e channel's assignees
    if ( payload.reminder !== -1 ) {
      historyItem.usersNotified = payload.assignee
    } else {
      historyItem.usersNotified = []
    }
    historyItem.zCusId = payload.zCusId


    // 2. prepare 'action' field acc. to payload.instr': 
    //  'Add' / 'Update' / 'Remove' history record        (task / comment / doc / note / blog )
    //  'Like' / 'Heart' / 'Happy' / 'Sad' history record (comment)
    // * only task and comment in notifications           (i.e. 'at' and 'by' fields)
    
    switch ( payload.instr ) {
      case 'Add':
        historyItem.action = 'Add'
        dispatch('history/addHistoryItem', historyItem, { root: true })
        break
      case 'Update':
        historyItem.action = 'Update'
        fuAction = 'Update'
        break
      case 'Remove':
        historyItem.action = 'Remove'
        fuAction = 'Remove'
        break
      case 'Delete':
        historyItem.action = 'Delete'
        fuAction = 'Delete'
    }

    // Proceed if 'Update' or 'Remove' or 'Delete'
    if ( fuAction !== '' ) {
      // Dig out the exiting historyItem from store, grab the id before being updated
      let mappedHistItem = rootState.history.historyItems.find(hItem => hItem.moduleId===payload.id)
      if ( mappedHistItem !== undefined ) {
        historyItem.id = mappedHistItem.id
        if ( fuAction == 'Remove' ) {
          historyItem.at = format(new Date(), "yyyy-MM-dd HH:mm")
          historyItem.usersNotified = []
        }
        if ( fuAction=='Update' || fuAction=='Remove' ) {
          dispatch('history/updateHistoryItem', historyItem, { root: true })
        }
        if ( fuAction == 'Delete' ) {
          dispatch('history/deleteHistoryItem', historyItem, { root: true })
        }
      }
    }

    // remove 'instr' field from payload
    delete payload.instr
    delete payload.usersNotified
    fuAction = ''
  },
  setTask({ commit }, payload) {
    commit('SET_TASKONLY', payload)
  }, 
  // When click "new subtask at goal in taskList / task"
  setNewTaskParent({ commit }, payload) {
    commit('SET_NEWTASKPARENT', payload)
  },
  setResetCardTaskPlan({ commit }, payload) {
    commit('SET_RESETCARDTASKPLAN', payload)
  },
  // setSelectedChannelIdRParam({ commit }, payload) {
  //   commit('SET_SELECTEDCHANNELIDPARAM', payload)
  // },
  setTaskBeforeUpdate({ commit }, payload) {
    commit('SET_TASKBEFOREUPDATE', payload)
  },
  setTasks({ commit }, payload) {
    commit('SET_TASKS', payload)
  },
  // refer to ADD_TOTASKS
  addTasktoTasks({ commit }, payload) {
    commit('ADD_TOTASKS', payload)
  },
  removeTaskinTasks({ commit }, payload) {
    commit('REMOVE_TASKINTASKS', payload)
  },
  setRootTask({ commit }, payload) {
    commit('SET_ROOTTASK', payload)
  },
  setTaskSidebar({ commit }, payload) {
    commit('SET_TASKSIDEBAR', payload)
  },
  setBlankTaskParent({ commit }, payload) {
    commit('SET_BLANKTASKPARENT', payload)
  },
  setTaskView({ commit, state }, payload) {
    commit('SET_TASKVIEW', payload)
  },
  // setSelectedTasks({ commit }, payload) {
  //   commit('SET_SELECTEDTASKS', payload)
  // },
  setAddTaskTag({ commit }, payload) {
    commit('SET_ADDTASKTAG', payload)
  },
  setTaskTags({ commit }, payload) {
    commit('SET_TASKTAGS', payload)
  },
  setTaskPlanView({ commit }, payload) {
    commit('SET_TASKPLANVIEW', payload)
  },
  setSortBy({ commit }, payload) {
    commit('SET_SORTBY', payload)
  },
  setFilterBy({ commit }, payload) {
    commit('SET_FILTERBY', payload)
  },
  setFilterValue({ commit }, payload) {
    commit('SET_FILTERVALUE', payload)
  },
  setDueFilterBy({ commit }, payload) {
    commit('SET_DUEFILTERBY', payload)
  },
  setsearchTaskString({ commit }, payload) {
    commit('SET_SEARCHTASKSTRING', payload)
  },
  setTaskIdToEditInTreeView({ commit }, payload) {
    commit('SET_TASKIDTOEDITINTREEVIEW', payload)
  },
  setNumOfOverdueTasks({ commit }, payload) {
    commit('SET_NUMOFOVERDUETASKS', payload)
  },
  setNumOfOutstandingTasks({ commit }, payload) {
    commit('SET_NUMOFOUTSTANDINGTASKS', payload)
  },
  setNumOfUpcomingTasks({ commit }, payload) {
    commit('SET_NUMOFUPCOMINGTASKS', payload)
  },
  setNumOfTodayTasks({ commit }, payload) {
    commit('SET_NUMOFTODAYTASKS', payload)
  },
  setNumOfAnytimeTasks({ commit }, payload) {
    commit('SET_NUMOFANYTIMETASKS', payload)
  },
  clearTasks({ commit }) {
    commit('CLEAR_TASKS')
  },
  setTasksGroupBy({ commit }, payload) {
    commit('SET_TASKSGROUPBY', payload)
  },
  setBooShowCompletedTasks({ commit }, payload) {
    commit('SET_BOOSHOWCOMPLETEDTASKS', payload)
  },
}

const mutations = {
  SET_ISDRAGGABLE(state, payload) {
    state.isDraggable = payload
  },
  SET_RENDERMAXTASKLEVEL(state, payload) {
    state.renderMaxTaskLevel = payload
  },
  SET_RESCHEDULETASKSVIEW(state, payload) {
    state.rescheduleTasksView = payload
  },
  SET_RESCHEDULEMODE(state, payload) {
    state.rescheduleMode = payload
  },
  SET_RESCHEDULINGLEVEL(state, payload) {
    state.reschedulingLevel = payload
  },
  SET_SAVERESCHEDULETASKS(state, payload) {
    state.saveRescheduleTasks = payload
  },
  SET_DATESMANUALLYSET(state, payload) {
    state.datesManuallySet = payload
  },
  ADDUPDATE_REFRESHDAYS(state, payload) {
    // payload is object (i.e. lday in 'List')
    let payloadIndex = state.refreshDays.findIndex(obj => 
      obj.id=== payload.id)
    if ( payloadIndex==-1 ) {
      state.refreshDays.push(payload)
    } else {
      state.refreshDays[payloadIndex] = payload
    }
  },
  SET_REFRESHDAYS(state, payload) {
    state.refreshDays=payload
  },
  ADDUPDATE_ORITASKLIST(state, payload) {
    let payloadIndex = state.oriTaskList.findIndex(obj => 
      obj.id=== payload.id)
    if ( payloadIndex==-1 ) {
      state.oriTaskList.push(payload)
    } else {
      state.oriTaskList[payloadIndex] = payload
    }
    // state.oriTaskList.push(payload)
  },
  SET_ORITASKLIST(state, payload) {
    state.oriTaskList=payload
  },
  SET_TASKDRAG(state, payload) {
    state.taskDrag = payload
  },
  SET_SELECTEDTASKIDPARAM(state, payload) {
    state.selectedTaskIdRParam = payload
  },
  SET_ROUTEFROMRPARAM(state, payload) {
    state.routeFromRParam = payload
  },
  ADD_CFTREEVALUES(state, payload) {
    state.cfTreeValues.push(payload)
  },
  SET_CFTREEVALUES(state, payload) {
    state.cfTreeValues = payload
  },
  SET_TASKTAGS(state, tags) {
    state.taskTags = tags
  },
  // add 'tags' array to state.taskTags
  // payload is 'tags' array
  // vs 'orderTagsFromTasks': no change in state.taskTags
  // 'addTask', 'getDeltaTasks'
  ADD_TASKTAGS(state, payload) {
    payload.forEach(newTag => {
      let newTagIndex = state.taskTags.indexOf(newTag)
      if ( newTagIndex==-1 && newTag.trim()!=='' ) {
        state.taskTags.push(newTag)
      }
    })
  },
  SET_ADDTASKTAG(state, payload) {
    state.addTaskTag = payload
  },
  SET_TASKPLANVIEW(state, payload) {
    state.taskPlanView = payload
  },
  SET_TASKONLY(state, payload) {
    // 1. Set the payload to state 'task'
    state.task = payload
    // state.task.update
    // console.log('SET_TASKONLY run with id: ' + state.task.id)
  },
  SET_NEWTASKPARENT(state, payload) {
    state.newTaskParent = payload
  },
  SET_RESETCARDTASKPLAN(state, payload) {
    state.resetCardTaskPlan = payload
  },
  SET_TASK(state, payload) {
    // 1. Set the payload to state 'task'
    state.task = payload
    // 2. Update 'task' into the existing "tasks" array
    state.tasks.forEach(eachTask => {
      if (eachTask.id === payload.id) {
        eachTask.module = payload.module
        eachTask.taskName = payload.taskName
        eachTask.type = payload.type
        eachTask.description = payload.description
        eachTask.milestone = payload.milestone
        eachTask.startOn = payload.startOn
        eachTask.dueOn = payload.dueOn
        eachTask.reminder = payload.reminder
        eachTask.status = payload.status
        eachTask.important = payload.important
        eachTask.assignee = payload.assignee
        eachTask.owner = payload.owner
        eachTask.tags = payload.tags
        eachTask.zParentId = payload.zParentId
        eachTask.zParentType = payload.zParentType
        eachTask.zLevel = payload.zLevel
        eachTask.zDependencyType = payload.zDependencyType
        eachTask.zDependentTask = payload.zDependentTask
        eachTask.createdAt = payload.createdAt
        eachTask.updatedAt = payload.updatedAt
        eachTask.deleted = payload.deleted
        eachTask.zCusId = payload.zCusId
        eachTask.zCF0 = payload.zCF0
        eachTask.zCF1 = payload.zCF1
        eachTask.zCF2 = payload.zCF2
        eachTask.zCF3 = payload.zCF3
        eachTask.zCF4 = payload.zCF4
        eachTask.zCF5 = payload.zCF5
        eachTask.zCF6 = payload.zCF6
        eachTask.zCF7 = payload.zCF7
        eachTask.zCF8 = payload.zCF8
        eachTask.zCF9 = payload.zCF9
        eachTask.zPosition = payload.zPosition
      } 
    })
    // state.tasks.update
    // if (payload.id !== undefined) {
    //   state.tpAddingTask = false
    // }
    // dispatch('setTpAddingTask', false)
    // commit('SET_TPADDINGTASK', false)
  },
  // refer to ADD_TOTASKS
  // ADD_TASKTOTASKS(state, task) {
  //   state.tasks.push(task)
  // }, 
  SET_TASKBEFOREUPDATE(state, taskBeforeUpdate) {
    state.taskBeforeUpdate = taskBeforeUpdate
  },
  SET_ROOTTASK(state, rootTask) {
    state.rootTask = rootTask
  },
  SET_TASKSIDEBAR(state, payload) {
    state.taskSidebar = payload
  },
  SET_BLANKTASKPARENT(state, blankTaskParent) {
    state.blankTaskParent = blankTaskParent
  },
  SET_TASKVIEW(state, taskView) {
    state.taskView = taskView
  },
  // SET_SELECTEDTASKS(state, payload) {
  //   state.selectedTasks = payload
  // },
  SET_SORTBY(state, payload) {
    state.sortBy = payload
  },
  SET_FILTERBY(state, payload) {
    state.filterBy = payload
  },
  SET_FILTERVALUE(state, payload) {
    state.filterValue = payload
  },
  SET_DUEFILTERBY(state, payload) {
    state.dueFilterBy = payload
    state.dueFilterBy.update
  },
  SET_TASKS(state, payload) {
    state.tasks.concat(payload)
    state.tasks = payload
  },
  ADD_TOTASKS(state, payload) {
    let isTaskFound = false
    state.tasks.forEach(task => {
      if (task.id === payload.id) {
        isTaskFound = true
      }
    })
    if (!isTaskFound) {
      state.tasks.push(payload)
    }
    // state.task = {}
    // state.tasks.update
    // console.log('task.js; task is added with id: ' + state.task.id)
    // console.log('payload under ADD_TOTASKS, just check: ' + payload.id)
    // state.task = state.tasks[state.tasks.length-1]
    // state.task.update
    // console.log(state.task.id)
    // find from tasks rather than directly add payload... because I need id !!
    // state.task = state.tasks.find(taskk => taskk.createdAt === payload.createdAt)
    // state.tasks = state.tasks.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
  },
  // i.e. add (delta) tasks to tasks
  ADD_DELTATASKS(state, payload) {
    // console.log('~~ Processing delta tasks')
    let isFound = false
    payload.forEach(deltaTask => {
      state.tasks.forEach(task => {
        if (task.id === deltaTask.id) {
          isFound = true
        }
      })
      if (!isFound) {
        state.tasks.push(deltaTask)
      } else {
        // console.log('Updating task: ' + deltaTask.taskName)
        // Still have to update the task!
        let updatedTaskIndex = state.tasks.findIndex(task => task.id == deltaTask.id)
        state.tasks[updatedTaskIndex] = deltaTask
        state.tasks.update
        isFound = false
      }
    })
    // state.tasks.update
  },
  REMOVE_TASKINTASKS(state, payload) {
    const index = state.tasks.indexOf(payload)
    if (index > -1) {
      state.tasks.splice(index, 1)
    }
    state.tasks.update
  },
  SET_SEARCHTASKSTRING(state, searchTaskString) {
    state.searchTaskString = searchTaskString
  },
  SET_TASKIDTOEDITINTREEVIEW(state, taskIdToEditInTreeView) {
    state.taskIdToEditInTreeView = taskIdToEditInTreeView
  },
  SET_NUMOFOVERDUETASKS(state, numOfOverdueTasks) {
    state.numOfOverdueTasks = numOfOverdueTasks
  },
  SET_NUMOFUPCOMINGTASKS(state, numOfUpcomingTasks) {
    state.numOfUpcomingTasks = numOfUpcomingTasks
  },
  SET_NUMOFOUTSTANDINGTASKS(state, numOfOutstandingTasks) {
    state.numOfOutstandingTasks = numOfOutstandingTasks
  },
  SET_NUMOFTODAYTASKS(state, numOfTodayTasks) {
    state.numOfTodayTasks = numOfTodayTasks
    state.isDueFiltersUpdated = true
  },
  SET_NUMOFANYTIMETASKS(state, numOfAnytimeTasks) {
    state.numOfAnytimeTasks = numOfAnytimeTasks
  },
  // Clearing during logout
  CLEAR_TASKS(state) {
    state.tasks = []
    state.taskSize = 0
    // state.selectedTasks = []
    state.task = {}
    state.taskBeforeUpdate = {}
    state.rootTask = {}
    state.blankTaskParent = {}
    state.taskTags = []
    state.sortBy = ''
    state.filterBy = ''
    state.filterValue = ''
    state.dueFilterBy = ''
    state.tasksGroupBy= ''
    state.taskView = ''
    state.taskPlanView = ''
    state.searchTaskString = ''
    state.dataFrom = ''
    state.dataTo = ''
    state.taskIdToEditInTreeView = ''
  },
  SET_TASKSGROUPBY(state, tasksGroupBy) {
    state.tasksGroupBy = tasksGroupBy
  },
  SET_BOOSHOWCOMPLETEDTASKS(state, payload) {
    state.booShowCompletedTasks = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
