// eslint-disable-next-line import/no-cycle
import { db } from '@/main'
export default {
  namespaced: true,

  state: {
    coupons: [],
    isCouponsLoaded: false,
    coupon: {},
  },

  actions: {
    async getCoupons({ commit }, payload) {
      try {
        let snapshot = await db.collection('coupons')
          .where('owner', '==', payload)
          .get();
        
        // console.log('Number of coupons found:', snapshot.size);
        
        let acoupons = [];
        snapshot.forEach(doc => {
          let appData = doc.data();
          appData.id = doc.id;
          acoupons.push(appData);
        });

        // console.log('Fetched Coupons:', acoupons);
        
        commit('SET_COUPONS', acoupons);
        commit('SET_ISCOUPONSLOADED', true);
        return acoupons; // Return the coupons
      } catch (error) {
        console.error('Error fetching coupons:', error);
        return []; // Return an empty array on error
      }
    },
    
    async getAllCoupons({ commit }) {
      try {
        let snapshot = await db.collection('coupons')
          .get();
        
        // console.log('Number of coupons found:', snapshot.size);
        
        let acoupons = [];
        snapshot.forEach(doc => {
          let appData = doc.data();
          appData.id = doc.id;
          acoupons.push(appData);
        });

        // console.log('Fetched Coupons:', acoupons);
        
        commit('SET_COUPONS', acoupons);
        commit('SET_ISCOUPONSLOADED', true);
        return acoupons; // Return the coupons
      } catch (error) {
        console.error('Error fetching coupons:', error);
        return []; // Return an empty array on error
      }
    },

    async addCoupon({ commit }, payload) {
      await db
        .collection('coupons')
        .add({
          promoCode: payload.promoCode,
          source: payload.source,
          sourceLink: payload.sourceLink,
          status: payload.status,
          createdAt: payload.createdAt,
          updatedAt: payload.updatedAt,
          category: payload.category,
          // appliedToPlan: payload.appliedToPlan, // e.g. 'Pro'
          appliedToUser: payload.appliedToUser,  // 'all' for marketer, userProfile.id as referee
          owner: payload.owner,
        })
        .then(function (docRef) {
          payload.id = docRef.id
        })
      commit('SET_COUPON', payload)
      commit('ADD_TOCOUPONS', payload)
    },
    
    // For Admin module
    async updateCoupon({ commit }, payload) {
      await db
        .collection('coupons')
        .doc(payload.id)
        .update({
          promoCode: payload.promoCode,
          source: payload.source,
          sourceLink: payload.sourceLink,
          status: payload.status,
          createdAt: payload.createdAt,
          updatedAt: payload.updatedAt,
          category: payload.category,
          // appliedToPlan: payload.appliedToPlan, // e.g. 'Pro'
          appliedToUser: payload.appliedToUser,  // 'all' for marketer, userProfile.id as referee
          owner: payload.owner,
        })
      commit('SET_COUPON', payload)
    },
  },

  getters: {},

  mutations: {
    SET_COUPONS(state, payload) {
      // console.log('Setting coupons:', payload);
      const uniqueCoupons = payload.filter(newCoupon => 
        !state.coupons.some(existingCoupon => existingCoupon.id === newCoupon.id)
      );
      state.coupons = state.coupons.concat(uniqueCoupons);
    },
    // SET_COUPONS(state, payload) {
    //   console.log('Setting coupons:', payload); // Check what you are setting
    //   state.coupons = payload
    // },
    SET_COUPON(state, payload) {
      state.coupon = payload
    },
    ADD_TOCOUPONS(state, payload) {
      let isCouponFound = false
      // state.coupons.forEach(coupon => {
      //   if (coupon.id === payload.id) {
      //     isCouponFound = true
      //   }
      // })
      if (!isCouponFound) {
        state.coupons.push(payload)
      }
    },
    SET_ISCOUPONSLOADED(state, payload) {
      state.isCouponsLoaded = payload
    },
  },

}
