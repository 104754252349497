import { db } from '@/main'

export default {
  namespaced: true,

  state: {
    histAuthItem: {},
    histAuthItems: [],
    muHistAuthItems: [],
    // loginInfoLogged: false,   // record login info has been logged
  },

  getters: {
  },

  mutations: {
    SET_HISTAUTHITEM(state, payload) {
        state.histAuthItem = payload
        state.histAuthItems.update
    },
    SET_HISTAUTHITEMS(state, payload) {
        state.histAuthItems = payload
    },
    SET_MUHISTAUTHITEMS(state, payload) {
      const uniqueAuthItems = payload.filter(newItem => 
        !state.muHistAuthItems.some(existingItem => existingItem.id === newItem.id)
      );
      state.muHistAuthItems = state.muHistAuthItems.concat(uniqueAuthItems);
    },
    // SET_MUHISTAUTHITEMS(state, payload) {
    //     state.muHistAuthItems = payload
    // },
    ADD_TOHISTAUTHITEMS(state, payload) {
      let ishistAuthItemFound = false
      state.histAuthItems.forEach(histAuthItem => {
        if (histAuthItem.id === payload.id) {
          ishistAuthItemFound = true
        }
      })
      if (!ishistAuthItemFound) {
        state.histAuthItems.push(payload)
      }
    },
    CLEAR_HISTAUTH(state) {
      state.histAuthItem = {}
      state.histAuthItems = []
    },
    // SET_LOGININFOLOGGED(state, payload) {
    //     state.loginInfoLogged = payload
    // },
  },

  actions: {
    // to be added to 'client admin'
    async getHistAuthItems({ commit }, payload) {
        // commit('SET_LOADINGSTATUS', 'loading')
        let snapshot = await db.collection('hist_auth')
          .where('zCusId', '==', payload)
          .get();
        let histAuthItems = [];
        snapshot.forEach(histAuthItem => {
            let appData = histAuthItem.data()
            appData.id = histAuthItem.id
            histAuthItems.push(appData)
        })
        commit('SET_HISTAUTHITEMS', histAuthItems)
        // commit('SET_LOADINGSTATUS', 'notLoading')
    },
    // Firebase - Get all Authenication History Items based based on matching 'email' field of authen 'hist_auth' table
    // payload is user.email
    // Firebase - Get all Authentication History Items based on matching 'email' field of authen 'hist_auth' table
    async getMUHistAuthItems({ commit }, payload) {
      try {
        const snapshot = await db.collection("hist_auth")
          .where('email', '==', payload)
          .limit(10) // Limit to 10 items for performance
          .get();
        
        const muHistAuthItems = [];
        snapshot.forEach(histAuthItem => {
          const appData = histAuthItem.data();
          appData.id = histAuthItem.id;
          muHistAuthItems.push(appData);
        });
        
        commit('SET_MUHISTAUTHITEMS', muHistAuthItems);
      } catch (error) {
        console.error('Error fetching authentication history items:', error);
      }
    },
    // async getMUHistAuthItems({ commit }, payload) {
    //     // commit('SET_LOADINGSTATUS', 'loading')
    //     console.log('--getAuth: ' + payload)
    //     let snapshot = await db.collection('hist_auth')
    //       .where('email', '==', payload)
    //       .limit(10)
    //       .get();
    //     let muHistAuthItems = [];
    //     snapshot.forEach(histAuthItem => {
    //         let appData = histAuthItem.data()
    //         appData.id = histAuthItem.id
    //         muHistAuthItems.push(appData)
    //     })
    //     commit('SET_MUHISTAUTHITEMS', muHistAuthItems)
    //     // commit('SET_LOADINGSTATUS', 'notLoading')
    // },
    async addHistAuthItem({ commit }, payload) {
        await db
            .collection('hist_auth')
            .add({
                userName: payload.userName,
                email: payload.email,
                title: payload.title,
                authType: payload.authType, // i.e. 'login' or 'logout
                at: payload.at,
                connection: payload.connection, // i.e. 'web' or 'app'
                geoInfo: payload.geoInfo, // i.e. 'web' or 'app'
                zCusId: payload.zCusId,
            })
            .then(function (histAuthRef) {
                payload.id = histAuthRef.id
            })
        commit('ADD_TOHISTAUTHITEMS', payload)
        commit('SET_HISTAUTHITEM', payload)
    },
    async updateHistAuthItem({ commit }, payload) {
      await db
        .collection('hist_auth')
        .doc(payload.id)
        .update({
          userName: payload.userName,
          email: payload.email,
          title: payload.title,
          authType: payload.authType, // i.e. 'login' or 'logout
          at: payload.at,
          connection: payload.connection, // i.e. 'web' or 'app'
          geoInfo: payload.geoInfo, // i.e. 'web' or 'app'
          zCusId: payload.zCusId,
        })
      commit('SET_HISTAUTHITEM', payload)
    },
    // async deleteHistAuthItem({ commit }, payload) {
    //     await db
    //         .collection('hist_auth')
    //         .doc(payload)
    //         .delete();
    //     // alert('The project item has been deleted.');
    //     commit('SET_HISTAUTHITEM', null)
    // },
    setHistAuthItems({ commit }, payload) {
        commit('SET_HISTAUTHITEMS', payload)
    },
    setHistAuthItem({ commit }, payload) {
        commit('SET_HISTAUTHITEM', payload)
    },
    clearHistAuth({ commit }) {
      commit('CLEAR_HISTAUTH')
    },
    // setLoginInfoLogged({ commit }, payload) {
    //   commit('SET_LOGININFOLOGGED', payload)
    // },
  },
}
