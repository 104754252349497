// inputDate:
// - e.g. '2022-08-07 00:00' : scheduled tasks
// - e.g. '2022-08-07 1' : unscheduled tasks

// outputDate:
// - datePortion, e.g. '2002-08-07'
// - timePortion, e.g. '00:00' or '1'
import { format, formatDistanceToNowStrict, isSameDay, isBefore,
  isAfter, addBusinessDays, addMinutes, differenceInMinutes,
  subBusinessDays, subMinutes, addMonths, addYears
} from 'date-fns'
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'dates',
  computed: {
    ...mapState({
      rescheduleMode: state => state.task.rescheduleMode,
    }),
    ...mapGetters({
        currentBreakPoint: 'app/currentBreakPoint'
    }),
  },
  methods: {
    datePortion(task) {
      // if no time elements in both dates, return due date
      if ( this.currentBreakPoint=='xs' ) {
        if ( this.isDayOnly(task) ) {
          return format(new Date(task.dueOn.split(' ')[0]), 'LLL d')
        } else {
          return format(new Date(task.startOn), 'LLL-d h:mm aaa')
        }
      } else {
        if ( this.isDayOnly(task) ) {
          // if 'reschedule tasks' button in todosidebar is hit
          if ( this.rescheduleMode!=='Auto' ) {
            return format(new Date(task.startOn.split(' ')[0]), 'LLL d')
              + ' - ' + format(new Date(task.dueOn.split(' ')[0]), 'LLL d')
          } else {
            return format(new Date(task.startOn.split(' ')[0]), 'LLL d')
              + ' - ' +
              format(new Date(task.dueOn.split(' ')[0]), 'LLL d')
          }
        } else {
          if ( this.rescheduleMode!=='Auto' ) {
            // else return start date
            return format(new Date(task.startOn.split(' ')[0]), 'LLL d')
          } else {
            return format(new Date(task.startOn), 'LLL-d h:mm aaa')
          }
        }
      }
    },
    timePortion(task) {
      // Display time if it is scheduled
      if ( this.isScheduled(task) ) {
        if ( this.isDayOnly(task) ) {
          return ''
        } else {
          if ( this.rescheduleMode!=='Auto' ) {
            return format(new Date(task.startOn), 'h:mm aaa')
          } else {
            return format(new Date(task.dueOn), 'LLL-d h:mm aaa')
          }
        }
      } else { return '' }
    },
    isScheduled(task) {
      if (task.startOn.split(' ')[1].length > 3) {
        return true
      } else {
        return false
      }
    },
    isDayOnly(task) {
      if ( task.startOn!==undefined && task.dueOn!==undefined ) {
        if ( task.startOn.substr(task.startOn.length-5)=='00:00' &&
          task.dueOn.substr(task.dueOn.length-5)=='00:00' ) {
            return true
          } else {
            return false
          }
        }
    },
    isSameDay(day1, day2) {
      let d1 = new Date(day1)
      let d2 = new Date(day2)
      return isSameDay(d1, d2)
    },
    isBefore(day1, day2) {
      let d1 = new Date(day1)
      let d2 = new Date(day2)
      if ( isBefore(d1, d2) ) {
        return true
      } else { return false }
    },
    isAfter(day1, day2) {
      let d1 = new Date(day1)
      let d2 = new Date(day2)
      if ( isAfter(d1, d2) ) {
        return true
      } else { return false }
    },
    friendlyDate(myt) {
      return formatDistanceToNowStrict(new Date(myt))
      // return myt
    },
    findNextDay(day1) {
      let d1 = new Date(day1)
      return this.setFormat(addBusinessDays(d1, 1), 'dayOnly')
    },
    findPreviousDay(day1) {
      let d1 = new Date(day1)
      return this.setFormat(subBusinessDays(d1, 1), 'dayOnly')
    },
    setFormat(day1, type) {
      // console.log('(setFormat)day1: ' + day1)
      // console.log('(setFormat)type: ' + type)
      let d1 = ''
      if ( day1 == 'today' ) {
        d1 = new Date()
      } else {
        d1 = new Date(day1)
      }
      // if ( day1.length>10 && day1.length<=20 ) {
      //   d1 = day1
      // } else {
        // d1 = new Date(day1)
      // } 
      // console.log('d1 is: ' + d1)
      switch (type) {
        case 'dayTime':
          return format(d1, 'yyyy-MM-dd HH:mm')
        case 'dayOnly':
          return format(d1, 'yyyy-MM-dd') + ' 00:00'
        case 'timeOnly':
          return format(d1, 'HH:mm')
        case 'dayNoTime':
          return format(d1, 'yyyy-MM-dd')
        case 'daySecond':
          return format(d1, 'yyyy-MM-dd HH:mm:ss')
        case 'position':
          return format(d1, "yyyyMMddHHmmssSSS")
        case 'businessDay': // e.g. Jan 15, 2024
          return format(d1, 'LLL d, yyyy')
        case 'businessDayOnly': // e.g. Jan 15
          return format(d1, 'LLL d')
        case 'businessDayTime':
          return format(d1, 'LLL d, HH:mm')
        // case 'formalDay': // e.g. 2024-01-15
        //   return format(d1, 'yyyy-MM-dd')
      }
    },
    // Check if the string is date or not
    // isValidDate(dateString) {
    //   return !isNaN(Date.parse(dateString));
    // },
    addMins(day1, mins) {
      let d1 = new Date(day1)
      return addMinutes(d1, mins)
    },
    addMths(day1, mths) {
      let d1 = new Date(day1)
      return addMonths(d1, mths)
    },
    addYrs(day1, yrs) {
      let d1 = new Date(day1)
      return addYears(d1, yrs)
    },
    subMins(day1, mins) {
      let d1 = new Date(day1)
      return subMinutes(d1, mins)
    },
    // i.e. day1 - day2
    diffMins(day1, day2) {
      let d1 = new Date(day1)
      let d2 = new Date(day2)
      return differenceInMinutes(d1, d2)
    },
    // subjectDays & befDays have both startOn & dueOn
    // move and return befDays dependent on subjectDays
    // 1. 'parent':   known Parent, find Child 1
    // 2. '':         known Child 1, find Child 2
    // 3. 'up':       known Child 2, find Child 1
    // 4. 'up_parent' known Child 1, find Parent
    moveDates(subjectDays, befDays, instruct) {
      let startDueDiff = this.diffMins(subjectDays.dueOn, subjectDays.startOn)
      switch ( instruct ) {
        case 'parent':
          subjectDays.startOn = befDays.startOn
          subjectDays.dueOn = this.setFormat(
              this.addMins(subjectDays.startOn, startDueDiff)
            , 'dayOnly')
          break
        case '':
          subjectDays.startOn = this.findNextDay(befDays.dueOn)
          subjectDays.dueOn = this.setFormat(
              this.addMins(subjectDays.startOn, startDueDiff)
            , 'dayOnly')
          break
        case 'up':
          subjectDays.dueOn = this.findPreviousDay(befDays.startOn)
          subjectDays.startOn = this.setFormat(
              this.subMins(subjectDays.dueOn, startDueDiff)
            , 'dayOnly')
          break
        case 'up_parent':
          subjectDays.startOn = befDays.startOn
          subjectDays.dueOn = this.setFormat(
              this.addMins(subjectDays.startOn, startDueDiff)
            , 'dayOnly')
      }
      return subjectDays
    },
    // return dueOn after moving startOn
    moveDueOn(newStartOn, task) {
      let dueOn = ''
        // console.log('name --- ' + task.taskName + ' - ' + task.startOn)
        // console.log('newStartOn --- ' + newStartOn)
      let startDueDiff = this.diffMins(task.dueOn, task.startOn)
      dueOn = this.setFormat(
        this.addMins(newStartOn, startDueDiff)
          , 'dayOnly')
        // console.log('results --- ' + dueOn)
      return dueOn
    },
    delay(n){
      return new Promise(function(resolve){
        setTimeout(resolve, n*1000);
      });
    },
    // dueDateStartAt(task) {
    //   // if no time elements in both dates, return due date
    //   if ( this.isDayOnly(task) ) {
    //       return format(new Date(task.dueOn), 'LLL d')
    //       // return format(new Date(task.startOn), 'LLL d') + ' - ' + format(new Date(task.dueOn), 'LLL d')
    //   } else {
    //   // else: return start date and time
    //     return format(new Date(task.startOn), 'LLL d h:mm aaa')
    //   }
    // },
  }
}